import { Drivers, ELD, InspectonIcon, GroupIcon } from '@mgk-eld/assets';
import { SidebarItemsType } from '../../types/sidebar';
import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import BusinessCenterOutlined from '@mui/icons-material/BusinessCenterOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import DirectionsCarOutlined from '@mui/icons-material/DirectionsCarOutlined';
import EqualizerSharp from '@mui/icons-material/EqualizerSharp';
import FormatListBulletedOutlined from '@mui/icons-material/FormatListBulletedOutlined';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import PersonPinCircleOutlined from '@mui/icons-material/PersonPinCircleOutlined';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import { AppConfig } from '@mgk-eld/utils';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import GroupsIcon from '@mui/icons-material/Groups';

const getPagesSection1 = (isDesktop: boolean) =>
  [
    {
      href: '/dashboard',
      icon: DashboardOutlined,
      title: 'Dashboard',
    },
    {
      href: '/logs',
      icon: FormatListBulletedOutlined,
      title: 'Logs',
    },
    {
      href: '/trackings',
      icon: PersonPinCircleOutlined,
      title: 'Trackings',
    },
    isDesktop && {
      href: '/errors',
      icon: WarningAmberOutlined,
      title: 'Errors',
    },
    {
      href: '/reports',
      icon: EqualizerSharp,
      title: 'Reports',
    },
    {
      href: '/wake-up',
      icon: AccessTimeOutlined,
      title: 'Wake Up',
    },
    {
      href: '/monitoring',
      icon: PersonalVideoIcon,
      title: 'Monitoring',
    },
    {
      href: '/dots',
      icon: InspectonIcon,
      title: 'DOT Inspections',
    },
    {
      href: '/events',
      icon: GroupIcon,
      title: 'Events',
    },
  ] as unknown as SidebarItemsType[];

const getPagesSection2 = (isDesktop: boolean) =>
  [
    {
      href: '/users',
      icon: PersonOutlineOutlined,
      title: 'Users',
    },
    {
      href: '/drivers',
      icon: Drivers,
      title: 'Drivers',
    },
    {
      href: '/eld',
      icon: ELD,
      title: 'ELD',
    },
    {
      href: '/vehicles',
      icon: DirectionsCarOutlined,
      title: 'Vehicles',
    },
    isDesktop && {
      href: '/companies',
      icon: BusinessCenterOutlined,
      title: 'Companies',
    },
    {
      href: '/accountusers',
      icon: GroupsIcon,
      title: 'Account Users',
    },
  ] as unknown as SidebarItemsType[];

const getPagesSection3 = (isDesktop: boolean, appConfig: AppConfig | null) => {
  return [
    !isDesktop && {
      href: '/user-profile',
      title: 'User Profile',
    },
    !isDesktop && {
      href: '/company-profile',
      title: 'Company Profile',
    },
    appConfig?.domain?.includes('mgk') && {
      externalHref: 'https://help.mgkeld.com/',
      title: 'Knowledge Base',
    },
    appConfig?.domain?.includes('mgk') &&
      isDesktop && {
        externalHref: 'https://help.mgkeld.com/contact',
        title: 'Feedback/Report',
      },
    !isDesktop && {
      href: '/logout',
      title: 'Logout',
    },
  ] as unknown as SidebarItemsType[];
};

const getNavItems = (isDesktop: boolean, appConfig: AppConfig | null) => [
  {
    title: '',
    divider: true,
    pages: getPagesSection1(isDesktop).filter((i) => i),
  },
  {
    title: '',
    divider: true,
    pages: getPagesSection2(isDesktop).filter((i) => i),
  },
  {
    title: '',
    divider: false,
    pages: getPagesSection3(isDesktop, appConfig).filter((i) => i),
  },
];

export default getNavItems;
