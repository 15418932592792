export const USER_ROLES = {
  driver: {
    id: 5,
    name: 'Driver',
    code: 'driver',
  },
  fleet_manager: {
    id: 4,
    name: 'Freet Manager',
    code: 'fleet_manager',
  },
  accounting: {
    id: 8,
    name: 'Accounting',
    code: 'accounting',
  },
  tech_support: {
    id: 9,
    name: 'Tech Support',
    code: 'tech_support',
  },
};
